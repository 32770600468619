import * as actions from './actionsType';
// import store from '../RootReducer/rootReducer';

let initialState = {
  headerMenu: null,
  footerMenu: null,
  lastMenu: null,
  headerMenuLoading: false,
  footerMenuLoading: false,
  lastMenuLoading: false,
};

let menuReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.SET_MENU:
      let menu = JSON.parse(JSON.stringify(action.menu));
      let slug = null;
      if (action.slug === 'main-menu') {
        slug = 'headerMenu';
      } else if (action.slug === 'footer-menu') {
        slug = 'footerMenu';
      } else {
        slug = 'lastMenu';
      }

      return {
        ...state,
        [slug]: menu,
        [slug + 'Loading']: false
      };
    case actions.START_FETCHING_MAIN_MENU:
      return {
        ...state,
        headerMenuLoading: true
      };
    case actions.FETCHING_MAIN_MENU_FAILED:
      return {
        ...state,
        headerMenuLoading: false
      };
    case actions.START_FETCHING_FOOTER_MENU:
      return {
        ...state,
        footerMenuLoading: true
      };
    case actions.FETCHING_FOOTER_MENU_FAILED:
      return {
        ...state,
        footerMenuLoading: false
      };
    case actions.START_FETCHING_LAST_MENU:
      return {
        ...state,
        lastMenuLoading: true
      };
    case actions.FETCHING_LAST_MENU_FAILED:
      return {
        ...state,
        lastMenuLoading: false
      };
    default:
      return state;
  }
};
export default menuReducer;
