import HTTP from './http.service';

export const getMenuLinks = () => {
  const config = {
    method: 'get',
    url: '/content/menu/general-links'
  };
  return HTTP(config);
}

export const getTranslation = () => {
  const config = {
    method: 'get',
    url: '/configuration/translation'
  };
  return HTTP(config);
}
export const fetchingMainSection = (slug = 'homepage') => {
  const config = {
    method: 'get',
    url: `/content/slider/${slug}`
  };
  return HTTP(config);
}

export const fetchingFaqFetured = () => {
  const config = {
    method: 'get',
    url: '/faq/featured'
  };
  return HTTP(config);
}

export const fetchingFaqPostById = (id) => {
  const config = {
    method: 'get',
    url: `/faq/${id}`
  };
  return HTTP(config);
}

export const fetchingDownload = () => {
  const config = {
    method: 'get',
    url: '/content/mobile_app_info/lebara-app'
  };
  return HTTP(config);
}

export const fetchingOffersOverlay = () => {
  const config = {
    method: 'get',
    url: '/content/promotions/order-sim'
  };
  return HTTP(config);
}

export const fetchingHomeOrderSim = () => {
  const config = {
    method: 'get',
    url: '/content/promotions/order-sim'
  };
  return HTTP(config);
}

export const fetchTermsConditions = () => {
  const config = {
    method: 'get',
    url: '/content/pages/terms'
  };
  return HTTP(config);
}

export const fetchServices = () => {
  const config = {
    method: 'get',
    url: '/content/pages/services'
  };
  return HTTP(config);
}

export const fetchRightsResponsibilities = () => {
  const config = {
    method: 'get',
    url: '/content/pages/rights-and-responsibilities'
  };
  return HTTP(config);
}

export const fetchDisclaimer = () => {
  const config = {
    method: 'get',
    url: '/content/pages/disclaimer'
  };
  return HTTP(config);
}

export const fetchActivateInternetSettings = () => {
  const config = {
    method: 'get',
    url: '/content/pages/activate-internet-settings'
  };
  return HTTP(config);
}

export const fetchCustomerData = (data) => {
  const config = {
    data: data,
    method: 'post',
    url: '/support/getCustomerInfo',
  }
  return HTTP(config);
}

export const getSRStatus = (data) => {
  const config = {
    data: data,
    method: 'post',
    url: '/support/getSRStatus',
  }
  return HTTP(config);
}
export const submitComplaint = (data) => {
  const config = {
    data: data,
    method: 'post',
    url: '/support/submitComplaint',
  };
  return HTTP(config);
}


export const postComplaint = (data) => {
  const config = {
    data: data,
    method: 'post',
    url: '/support/customer-complain',
  };
  return HTTP(config);
}

export const fetchSupportDataResult = (key) => {
  const config = {
    method: 'get',
    url: '/faq/search?query=' + key,
  };
  return HTTP(config);
}

export const fetchPostPaid = () => {
  const config = {
    method: 'get',
    url: '/content/pages/postpaid'
  };
  return HTTP(config);
}

export const fetchHeaderDataResult = (key, page = 1, per_page = 10) => {
  const config = {
    method: 'get',
    url: `/content/search?query=${key}&page=${page}&per_page=${per_page}`
  };
  return HTTP(config);
}

export const getRates = () => {
  const config = {
    method: 'get',
    url: '/content/international_call_rates'
  };
  return HTTP(config);
}

export const getPageContent = (slug) => {
  const config = {
    method: 'get',
    url: `/content/pages/${slug}`,
    headers: {
      'x-protocol-version': 'v2',
    }
  };
  return HTTP(config);
}
export const getPageContentV3 = (slug) => {
  const config = {
    method: 'get',
    url: `/content/pages/${slug}`,
    headers: {
      'x-protocol-version': 'v3',
    }
  };
  return HTTP(config);
}
export const getPackages = () => {
  const config = {
    method: 'get',
    url: `/plans`,
  };
  return HTTP(config);
}
