import HTTP from './http.service';

export const getPlans = (ids) => {
  let url = `/plans?external_ids=${ids}`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPromotedPlans = () => {
  let url = `/plans/promoted`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getMNPPlans = () => {
  let url = `/lookup/mnp-plans`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPackageInfo = (slug) => {
  let url = `/plans/category/${slug}`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPackageInfoBySlug = (slug) => {
  let url = `/plans/${slug}`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPackageInfoById = (id) => {
  let url = `/plans/category/${id}/items`;
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPackageCategories = () => {
  let url = '/plans/categories';
  const config = {
    method: 'get',
    url: url
  };
  return HTTP(config);
}

export const getPackages = (featured = false) => {
  const config = {
    method: 'get',
    url: `/plans?page=1&per_page=10&featured=${featured}`
  };

  return HTTP(config);
}

export const getBundels = (feature = false) => {
  let featureParam = ''
  if (feature) {
    featureParam = 'tag=feature'
  }
  const config = {
    method: 'get',
    url: `/bundles?page=1&availability=web&per_page=10&${featureParam}`
  };

  return HTTP(config);
}

export const getPromotions = (featured = 0) => {
  const config = {
    method: 'get',
    url: `/offers?page=1&per_page=10&availability=web&featured=${featured}`
  };

  return HTTP(config);
}

export const getOfferBySlugId = (slugId) => {
  const config = {
    method: 'get',
    url: `/offers/${slugId}`
  };

  return HTTP(config);
}

export const purchaseBundle = (external_id) => {
  const config = {
    method: 'post',
    url: `/purchase/bundle`,
    headers: {
      'x-protocol-version': 'v3',
    },
    data: {
      "bundles": [
        external_id
      ]
    }
  };

  return HTTP(config);
}

export const validateSubscriberEligibilty = (mobile_number, plan_external_id) => {
  const config = {
    method: 'get',
    url: `/plans/validate-subscriber-eligibilty?mobile_number=${mobile_number}&plan_external_id=${plan_external_id}`
  };

  return HTTP(config);
}