// import "babel-polyfill";
import React from 'react';
import './App.scss';
import Layout from 'layout/Layout';
import ReactGA from 'react-ga';
import { getPath } from './util'
import { withRouter } from 'react-router-dom';
import { GET_URL_PATH_FIRST_PART } from './constants'

let template = null;


const App = (props) => {

  const { history, location } = props;

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  //////////////////////////
  // React.useEffect(() => {
  //   ReactGA.initialize(Settings.REACT_APP_GOOGLE_ANALYTICS_APP_ID);

  //   history.listen((newLocation, action) => {
  //     ReactGA.pageview(window.location.pathname);
  //     window.fbq('track', 'PageView');
  //   });

  // }, [history]);
  //////////////////////////

  const path = getPath(location.pathname, GET_URL_PATH_FIRST_PART);
  const layoutTemplate = React.useMemo(() => {
    switch (path) {
      case 'hawakom-faq':
      case 'loyalty-faq':
      case 'customer-complaints-app':
        template = 'NoHeaderFooter';
        break;
      case 'dashboard':
        template = 'DashboardLayout'
        break;
        case 'payinvoice':
          template = 'FooterAppLayout'
          break;
      default:
        template = 'DefaultLayout'
    }

    return (
      <Layout template={template} />
    );
  }, [path]);

  return layoutTemplate;

}

export default withRouter(App);