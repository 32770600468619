import React, { Suspense } from 'react';

let layout = null;

const DefaultLayout = React.lazy(() => import(/* webpackChunkName: "Default layout" */ `./Default`));
const DashboardLayout = React.lazy(() => import(/* webpackChunkName: "Dashboard layout" */ `./Dashboard`));
const NoHeaderFooter = React.lazy(() => import(/* webpackChunkName: "NoHeaderFooter layout" */ `./NoHeaderFooter`));
const FooterAppLayout = React.lazy(() => import(/* webpackChunkName: "NoHeaderFooter layout" */ `./FooterAppLayout`));

const Layout = React.memo((props) => {
    const { template } = props;

    switch (template) {
        case 'NoHeaderFooter':
            layout = <NoHeaderFooter />;
            break;
        case 'DashboardLayout':
            layout = <DashboardLayout />
            break;
            case 'FooterAppLayout':
                layout = <FooterAppLayout />
                break;
        default:
            layout = <DefaultLayout />
    }
    return <Suspense fallback={null}>{layout}</Suspense>
})
export default Layout;