import Menu from 'services/menu.service';
import * as actions from './actionsType';

export const setMenu = (slug, menu) => {
  return { type: actions.SET_MENU, menu: menu, slug: slug };
};

export const startFetchingMainMenu = () => {
  return { type: actions.START_FETCHING_MAIN_MENU };
};
export const fetchingMainMenuFailed = () => {
  return { type: actions.FETCHING_MAIN_MENU_FAILED };
};

export const startFetchingLastMenu = () => {
  return { type: actions.START_FETCHING_LAST_MENU };
};
export const fetchingLastMenuFailed = () => {
  return { type: actions.FETCHING_LAST_MENU_FAILED };
};

export const startFetchingFooterMenu = () => {
  return { type: actions.START_FETCHING_FOOTER_MENU };
};
export const fetchingFooterMenuFailed = () => {
  return { type: actions.FETCHING_FOOTER_MENU_FAILED };
};

export const fetchMenu = (slug) => {
  return (dispatch) => {
    let slugArr = slug.split('-');
    for (const key in slugArr) {
      slugArr[key] =
        slugArr[key].charAt(0).toUpperCase() + slugArr[key].substring(1);
    }
    let funcName = 'startFetching' + slugArr.join('');
    if (funcName === 'startFetchingMainMenu') {
      dispatch(startFetchingMainMenu());
    } else if (funcName === 'startFetchingFooterMenu') {
      dispatch(startFetchingFooterMenu());
    } else if (funcName === 'startFetchingFooterLast') {
      dispatch(startFetchingLastMenu());
    }

    Menu.fetchingMenu(slug)
      .then((response) => {
        let menu = response.data.result;

        dispatch(setMenu(slug, menu));
      })
      .catch((error) => {
        // dispatch(['fetching' + slugArr.join('') + 'Failed']());
        if (funcName === 'fetchingMainMenuFailed') {
          dispatch(fetchingMainMenuFailed());
        } else if (funcName === 'fetchingFooterMenuFailed') {
          dispatch(fetchingFooterMenuFailed());
        } else if (funcName === 'fetchingLastMenuFailed') {
          dispatch(fetchingLastMenuFailed());
        }
      });
  };
};
