import {
  register,
  getSubscribers,
  initiateUser,
  userLogin,
  requestOtp,
  validateOtp,
  userChangePassword,
  socialLogin,
  UserLogout,
} from 'services/user.service';
import * as actions from './actionsType';
import {
  setStorageElement,
  removeStorageElement,
  getStorageElement,
} from 'services/storage.service';
import HTTP from 'services/http.service';
import * as constants from '../../constants';
import {
  initialUserDashboard,
  resetUserInfo,
} from 'stores/Dashboard/dashboardActions';
import { handleError } from '../../util';
import Translation from 'services/translation.service';

import store from 'stores/RootReducer/rootReducer';

const updateAuthToken = (authToken) => {
  window.auth = authToken;
  HTTP.set_session(authToken);
  setStorageElement('authToken', authToken);
};
export const setUserDataInfo = (userInfo) => {
  HTTP.set_session(userInfo['auth_token']);
  setStorageElement('userInfo', userInfo);
  setStorageElement('authToken', userInfo['auth_token']);
  return {
    type: actions.SET_USER_DATA_INFO,
    userInfo: userInfo,
  };
};
export const updateUserDataInfo = (userInfo) => {
  setStorageElement('userInfo', userInfo);
  return {
    type: actions.SET_USER_DATA_INFO,
    userInfo: userInfo,
  };
};

export const removeUserStorage = () => {
  removeStorageElement('authToken');
  removeStorageElement('first_name');
  removeStorageElement('last_name');
  removeStorageElement('userInfo');
  removeStorageElement('loggedIn');
  removeStorageElement('subAccountId');
};

export const setUserStorage = (
  auth_token,
  first_name,
  last_name,
  userInfo = null
) => {
  setStorageElement('authToken', auth_token);
  setStorageElement('first_name', first_name);
  setStorageElement('last_name', last_name);
  setStorageElement('loggedIn', true);
  if (userInfo) {
    setStorageElement('userInfo', userInfo);
  }
};
export const startLogin = () => {
  return {
    type: actions.LOGIN_START,
  };
};
export const loginFailed = (error) => {
  return {
    type: actions.LOGIN_FAILED,
    errorMsg: error,
  };
};

export const loginSuccess = (
  auth_token,
  email,
  mobile_number,
  first_name,
  last_name,
  userInfo,
  subscribersList
) => {
  setUserStorage(auth_token, first_name, last_name, userInfo);

  return {
    type: actions.LOGIN_SUCCESS,
    auth_token: auth_token,
    first_name: first_name,
    last_name: last_name,
    mobile_number: mobile_number,
    email: email,
    userInfo: userInfo,
    subscribersList: subscribersList,
    subscriptionType: userInfo.properties.subscription_type,
    error: null,
  };
};

///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

export const startRegister = () => {
  return {
    type: actions.REGISTER_START,
  };
};
export const registerFailed = (error) => {
  return {
    type: actions.REGISTER_FAILED,
    errorMsg: error.response.data.message,
    errorMsgKey: error.response.data.messageKey,
  };
};

export const registerSuccess = () => {
  return {
    type: actions.REGISTER_SUCCESS,
  };
};

export const registerUser = (email, password, token) => {
  return (dispatch) => {
    dispatch(startRegister());

    const config = {
      user: {
        email: email,
        password: password,
        password_confirmation: password,
      },
    };

    register(config, token)
      .then((response) => {
        updateAuthToken(response.data.auth_token);

        getSubscribers(response.data.auth_token).then((subscribers) => {
          store.dispatch(initialUserDashboard());
          dispatch(
            loginSuccess(
              response.data.auth_token,
              response.data.email,
              response.data.mobile_number,
              response.data.first_name,
              response.data.last_name,
              response.data,
              subscribers.data
            )
          );
          dispatch(registerSuccess());
        });
      })
      .catch((error) => {
        dispatch(registerFailed(error));
      });
  };
};

export const resetError = () => {
  removeUserStorage();
  return {
    type: actions.RESET_ERROR,
  };
};

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
export const logoutSuccess = (goToHome = false, toPage = null) => {
  // Clear session storage
  removeUserStorage();
  // Clear HTTP session
  HTTP.set_session(null);

  // Generate new auth_token and update HTTP.auth_token + sessionStorage
  initiateUser()
    .then((response) => {
      if (response) {
        HTTP.set_session(response.data['auth_token']);
        setStorageElement('authToken', response.data['auth_token']);
        store.dispatch(resetUserInfo());
        store.dispatch({
          type: actions.UPDATE_AUTH_TOKEN,
          auth_token: response.data['auth_token'],
        });
        if (toPage && toPage !== 'Keep_Same_Page') {
          window.location = `${window.location.origin}/${toPage}`;
        }else
        if (goToHome) {
          window.location = '/' + window.appLang;
        }  
      }
    })
    .catch((error) => {});

  return {
    type: actions.LOGOUT,
  };
};

export const getSubAccount = (authToken) => {
  return (dispatch) => {
    dispatch({ type: actions.FETCH_SUB_ACCOUNTS_START });
    getSubscribers(authToken)
      .then((subAccounts) => {
        const selectedSubscriber = subAccounts.data.filter(
          (item) => item.user.is_main
        );
        if (selectedSubscriber.length) {
          selectedSubscriber[0].subscription_id = constants.MAIN;
          dispatch({
            type: actions.FETCH_SUB_ACCOUNTS_SUCCESS,
            subAccounts: subAccounts.data,
            selectedSubscriber: selectedSubscriber[0],
          });
        } else {
          throw new Error(Translation.key('generic_something_went_wrong_msg'));
        }
      })
      .catch((error) => {
        dispatch({
          type: actions.FETCH_SUB_ACCOUNTS_FAILED,
          errorMsg: handleError(error),
        });
      });
  };
};

export const loginOtp = (authToken) => {
  return (dispatch) => {
    dispatch({ type: actions.LOGIN_OTP_START });
    getSubscribers(authToken)
      .then((subAccounts) => {
        const selectedSubscriber = subAccounts.data.filter(
          (item) => item.user.is_main
        );
        if (selectedSubscriber.length) {
          selectedSubscriber[0].subscription_id = constants.MAIN;
          dispatch({
            type: actions.LOGIN_OTP_SUCCESS,
            subAccounts: subAccounts.data,
            selectedSubscriber: selectedSubscriber[0],
          });
        } else {
          throw new Error(Translation.key('generic_something_went_wrong_msg'));
        }
      })
      .catch((error) => {
        dispatch({
          type: actions.LOGIN_OTP_FAILED,
          errorMsg: handleError(error),
        });
      });
  };
};

export const login = (emailPhone, password) => {
  return (dispatch) => {
    dispatch(startLogin());

    const config = {
      auth: {
        email_or_mobile: emailPhone,
        password: password,
      },
    };

    userLogin(config)
      .then((response) => {
        updateAuthToken(response.data.auth_token);
        getSubscribers(response.data.auth_token)
          .then((subscribers) => {
            store.dispatch(initialUserDashboard());
            dispatch(
              loginSuccess(
                response.data.auth_token,
                response.data.email,
                response.data.mobile_number,
                response.data.first_name,
                response.data.last_name,
                response.data,
                subscribers.data
              )
            );
          })
          .catch((error) => {});
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

//////////////////////////////////////////////////////////////////
export const switchAccountData = (subAccountId) => {
  return { type: actions.SWITCH_ACCOUNT, subAccountId: subAccountId };
};
//////////////////////////////////////////////////////////////////
export const switchAccount = (subAccountId) => {
  // return { type: actions.SWITCH_ACCOUNT, subAccountId: subAccountId }
  return (dispatch) => {
    dispatch(switchAccountData(subAccountId));
  };
};
//////////////////////////////////////////////////////////////////
export const logout = (toPage = null) => {
  
  return async (dispatch) => {
    try {
      // Ensure the API call is awaited
      await UserLogout();
      // If the logout API call succeeds, proceed with local logout
      dispatch(logoutSuccess(true, toPage));
    } catch (error) {
      // If the logout API call fails, log the error and still proceed with local logout
      console.error('Logout failed: ', error);
      dispatch(logoutSuccess(true, toPage));
    }
  };
};
//////////////////////////////////////////////////////////////////
export const logoutAfterChangingPassword = (resetFlow = false) => {
  return (dispatch) => {
    const defaultLang = 'en';
    const url = store ? store.getState().layout.iso : defaultLang;
    if (resetFlow) {
      dispatch(logoutSuccess(false, 'Keep_Same_Page'));
    } else {
      dispatch(logoutSuccess(false, `${url}/login`));
    }
  };
};

////////////////////////requestOtpCode////////////////////////////
export const requestOtpCode = (
  mobileNumber,
  source = null,
  callBack = null
) => {
  return (dispatch) => {
    dispatch(startRequestOtpCode());
    let config = {
      otp: {
        mobile_number: mobileNumber,
        source,
      },
    };
    requestOtp(config)
      .then((response) => {
        if (callBack) {
          callBack();
        }
        dispatch(requestOtpCodeSuccess(mobileNumber, response.data));
      })
      .catch((error) => {
        dispatch(requestOtpCodeFailed(error));
      });
  };
};
export const startRequestOtpCode = () => {
  return { type: actions.REQUEST_OTP_CODE_START };
};
export const requestOtpCodeSuccess = (mobileNumber, numberDetails) => {
  // setUserStorage(numberDetails.auth_token, numberDetails.first_name, numberDetails.last_name, numberDetails);

  return {
    type: actions.REQUEST_OTP_CODE_SUCCESS,
    numberDetails: numberDetails,
    auth_token: numberDetails.auth_token,
    first_name: numberDetails.first_name,
    last_name: numberDetails.numberDetailslast_name,
    mobile_number: mobileNumber,
    email: numberDetails.email,
  };
};
export const requestOtpCodeFailed = (error) => {
  return {
    type: actions.REQUEST_OTP_CODE_FAILED,
    errorMsg: error.response.data,
  };
};
export const cancelRequestOtpCodeSuccess = () => {
  return {
    type: actions.CANCEL_REQUEST_OTP_CODE_SUCCESS,
  };
};
////////////////////////Validate OTP////////////////////////////
export const startValidateOtpCode = () => {
  return { type: actions.VALIDATE_OTP_CODE_START };
};
export const validateOtpCodeSuccess = (
  auth_token,
  email,
  mobile_number,
  first_name,
  last_name,
  otp,
  userInfo = null
) => {
  setUserStorage(auth_token, first_name, last_name, userInfo);
  return {
    type: actions.VALIDATE_OTP_CODE_SUCCESS,
    auth_token: auth_token,
    email: email,
    mobile_number: mobile_number,
    first_name: first_name,
    last_name: last_name,
    otp: otp,
    userInfo: userInfo,
  };
};
export const validateOtpCodeFailed = (error) => {
  return {
    type: actions.VALIDATE_OTP_CODE_FAILED,
    errorMsg: error.response?.data,
  };
};

export const validateOtpCode = (
  mobileNumber,
  otp,
  callBack = null,
  source = ''
) => {
  return (dispatch) => {
    dispatch(startValidateOtpCode());
    let config = {
      otp: {
        mobile_number: mobileNumber,
        source,
        otp: otp,
      },
    };
    validateOtp(config)
      .then((response) => {
        updateAuthToken(response.data.auth_token);

        dispatch(
          validateOtpCodeSuccess(
            response.data.auth_token,
            response.data.email,
            response.data.mobile_number,
            response.data.first_name,
            response.data.last_name,
            otp,
            response.data
          )
        );
        if (callBack) {
          callBack();
        }
      })
      .catch((error) => {
        dispatch(validateOtpCodeFailed(error));
      });
  };
};

export const resetBundleSubscribtion = () => {
  return { type: actions.BUNDLE_SUBSCRIBTION_RESET };
};
////////////////// Change Password //////////////////
export const startChangePassword = () => {
  return { type: actions.CHANGE_PASSWORD_START };
};
export const changePasswordSuccess = () => {
  return { type: actions.CHANGE_PASSWORD_SUCCESS };
};
export const changePasswordFailed = (error) => {
  return {
    type: actions.CHANGE_PASSWORD_FAILED,
    errorMsg: error,
  };
};
export const changePassword = (password, token, resetPassword = false) => {
  return (dispatch) => {
    dispatch(startChangePassword());
    let config = {
      user: {
        password: password,
      },
    };

    userChangePassword(config, token)
      .then((response) => {
        dispatch(changePasswordSuccess());
        // if (!resetPassword) {
        dispatch(logoutAfterChangingPassword(resetPassword));
        // }
      })
      .catch((error) => {
        let errorObj = 'Change password failed';
        if (error.response) {
          errorObj = error.response.data.message;
        }
        dispatch(changePasswordFailed(errorObj));
      });
  };
};
////////////////////////////////////////////////////

//1
export const startSocialSignin = (provider, providerToken) => {
  return {
    type: actions.SOCIAL_SIGNIN_START,
    provider: provider,
    providerToken: providerToken,
  };
};

//2
export const socialSigninSuccess = (
  auth_token,
  email,
  mobile_number,
  first_name,
  last_name,
  redirectURL
) => {
  return {
    type: actions.SOCIAL_SIGNIN_SUCCESS,
    auth_token: auth_token,
    email: email,
    mobile_number: mobile_number,
    first_name: first_name,
    last_name: last_name,
    redirectURL: redirectURL,
  };
};

//3
export const socialSigninFailed = (error, reason = null) => {
  return {
    type: actions.SOCIAL_SIGNIN_FAILED,
    errorMsg: error.response.data.message,
    reason: reason,
  };
};

//4
export const socialSignin = (provider, socialToken) => {
  return (dispatch) => {
    dispatch(startSocialSignin(provider, socialToken));

    socialLogin(provider, socialToken)
      .then((response) => {
        let { auth_token, email, mobile_number, first_name, last_name } =
          response.data;
        dispatch(
          socialSigninSuccess(
            auth_token,
            email,
            mobile_number,
            first_name,
            last_name,
            'dashboard'
          )
        );
      })
      .catch((error) => {
        if (error.response.data.code === -2) {
          // not linked
          dispatch(socialSigninFailed(error, 'not_linked'));
        }
      });
  };
};
////////////////////////////////////////////////////

export const resetErrors = () => {
  return { type: actions.RESET_ERRORS };
};

export const socialSignupFailed = (error) => {
  return {
    type: actions.SOCIAL_SIGNUP_FAILED,
    errorMsg: error.message,
  };
};
////////////////////////////////////////////////////
export const setWallets = (wallets) => {
  return (dispatch) => {
    dispatch({ type: actions.GETTING_WALLETS_SUCCESS, wallets: wallets });
  };
};
////////////////////////////////////////////////////
export const setTransferableDenominations = (denominations) => {
  return (dispatch) => {
    dispatch({
      type: actions.GETTING_TRANSFERABLE_DENOMINATIONS_SUCCESS,
      denominations: denominations,
    });
  };
};
////////////////////////////////////////////////////
export const updateHistoryActiveFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_HISTORY_ACTIVE_FILTER,
      filter: filter,
    });
  };
};
