import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import layoutReducer from '../Layout/layoutReducer';
import userReducer from '../User/userReducer';
import loadingReducer from '../Loading/loadingReducer';
import menuReducer from '../Menu/menuReducer';
import faqReducer from '../Faq/faqReducer';
import shopReducer from '../Shop/shopReducer';
import mapReducer from '../Map/mapReducer';
import dashboardReducer from '../Dashboard/dashboardReducer';
import homePageReducer from '../Homepage/homepageReducer';
import Settings from '../../services/settings.service'
const rootReducer = combineReducers({
  layout: layoutReducer,
  user: userReducer,
  loading: loadingReducer,
  menu: menuReducer,
  faq: faqReducer,
  shop: shopReducer,
  map: mapReducer,
  dashboard: dashboardReducer,
  homePage: homePageReducer,
});
const composeEnhancers =Settings.REACT_APP_SHOW_REDUX? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose:compose
let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;