import * as actions from './actionsType';

let initialState = {
  id: null,
  title: null,
  details: null,
  slug: null,
  url: null,
  image: null,
  video_url: null,
  feature: null,
  show_in_mobile: null,
  show_in_web: null,
  activeCategoryId: null,
  activePostId: null,
  loading: false,
  error: null
};
let faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_FAQ_POST_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case actions.FETCH_FAQ_POST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        id: action.post.id,
        title: action.post.title,
        details: action.post.details,
        slug: action.post.slug,
        url: action.post.url,
        image: action.post.image,
        video_url: action.post.video_url,
        feature: action.post.feature,
        show_in_mobile: action.post.show_in_mobile,
        show_in_web: action.post.show_in_web
      };

    case actions.FETCH_FAQ_POST_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };

    case actions.SET_ACTIVE_POST:
      return {
        ...state,
        activePostId: action.postId
      };
    case actions.SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategoryId: action.categoryId
      };
    case actions.CLEAER_POST_INFO:
      return {
        ...state,
        activePostId: null,
        error: null,
        loading: false,
        id: null,
        title: null,
        details: null,
        slug: null,
        url: null,
        image: null,
        video_url: null,
        feature: null,
        show_in_mobile: null,
        show_in_web: null
      };

    default:
      return state;
  }
};
export default faqReducer;
