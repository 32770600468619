import HTTP from './http.service';
class DashboardService {

    static getDashboard() {
        const config = {
            method: 'get',
            url: '/dashboard'
            
        };
        return HTTP(config);
    }
    static getRechargeHistory() {
        const config = {
            method: 'get',
            url: '/recharge/meter-history'
        };
        return HTTP(config);
    }

    static subscribeVas = (id) => {
        const config = {
            method: 'post',
            url: `/services/subscribe`,
            data: {
                "id": id
            }
        };
        return HTTP(config);
    }

}

export default DashboardService;
