import HTTP from './http.service';
import base64 from 'base-64';
import { getStorageElement, setStorageElement } from './storage.service';
import { initialUserDashboard } from 'stores/Dashboard/dashboardActions';
import * as actions from '../stores/User/actionsType';
import { v4 as uuidv4 } from 'uuid';
import Settings from './settings.service';
import { loginFailed } from '../stores/User/userActions';
const store = require('stores/RootReducer/rootReducer');

export const getUserAuth = function (UserData) {
  const config = {
    method: 'post',
    url: '/auth/device',
    data: UserData,
    headers: {
      needAutherization: false,
    },
  };
  return HTTP(config);
};

//////////////////////////////
export const getUserLogin = function (authToken) {
  const config = {
    method: 'get',
    url: '/users/current',
    headers: {
      Authorization: authToken,
    },
  };
  return HTTP(config);
};
export const UserLogout = function () {
  const authToken = HTTP.get_session(); // Get the current authToken from the HTTP object

  const config = {
    method: 'post',
    url: '/users/current/logout',
    headers: {
      Authorization: authToken,
    },
  };
  return HTTP(config);
};

//////////////////////////////
export const getSubscribers = (authToken) => {
  const config = {
    method: 'get',
    url: '/users/subscribers',
    headers: {
      Authorization: authToken,
    },
  };

  return HTTP(config);
};
//////////////////////////////
export const deleteSubAccountById = (id) => {
  const config = {
    method: 'delete',
    url: `/users/subscribers/${id}`,
  };
  return HTTP(config);
};
//////////////////////////////
export const initiateUser = function () {
  // 1- get uuid and authToken from sessionStorage
  let uuid = getStorageElement('uuid');
  let authToken = getStorageElement('authToken');

  // 2- check if uuid and authToken exist
  if (uuid && authToken) {
    if (!HTTP.get_session()) {
      HTTP.set_session(authToken);
      HTTP.set_device_id(uuid);
    }

    // check if this authToken logged in or not;
    return getUserLogin(authToken)
      .then((response) => {
        let fname = response.data.first_name;
        let lname = response.data.last_name;
        let mobileNumber = response.data.mobile_number;
        let email = response.data.email;

        sessionStorage.setItem('first_name', fname);
        sessionStorage.setItem('last_name', lname);

        store.default.dispatch(initialUserDashboard());
        getSubscribers(authToken)
          .then((subscribers) => {
            store.default.dispatch({
              type: actions.LOGIN_SUCCESS,
              auth_token: authToken,
              first_name: fname,
              last_name: lname,
              mobile_number: mobileNumber,
              email: email,
              userInfo: response.data,
              subscribersList: subscribers.data,
              subscriptionType: response.data.properties.subscription_type,
            });

            HTTP.set_device_id(uuid);

            let data = {
              data: {
                auth_token: authToken,
              },
            };
            return new Promise((resolve, reject) => {
              resolve(data);
            });
          })
          .catch((error) => {});
      })
      .catch((error) => {
        // save in HTTP Object to use them in calling API
        // HTTP.set_device_id(uuid);
        // HTTP.set_session(authToken);
        // return Promise.resolve();
        store.default.dispatch(loginFailed(error));
        let data = {
          data: {
            auth_token: authToken,
          },
        };

        HTTP.set_device_id(uuid);
        return new Promise((resolve, reject) => {
          resolve(data);
        });
      });
  } else {
    store.default.dispatch(loginFailed(''));
    if (!uuid) {
      // call [uuid] to generate uuid
      let uuidCode = uuidv4();
      HTTP.set_device_id(uuidCode);
      setStorageElement('uuid', uuidCode);

      // call [/auth/device] to generate  authToken
      return getUserAuth({ device_id: uuidCode });
    } else {
      return getUserAuth({ device_id: uuid });
    }
  }
};
//////////////////////////////
export const userLogin = function (UserData) {
  const HeadersData = getStorageElement('authToken');
  const userName = UserData.auth.email_or_mobile;
  const password = UserData.auth.password;
  const config = {
    method: 'post',
    url: '/auth',
    // data: UserData
    headers: {
      Authorization:
        HeadersData + ',' + `Basic ${base64.encode(`${userName}:${password}`)}`,
      payloadLogin: true,
    },
  };
  return HTTP(config);
};
////////////////////////////
export const register = function (UserData, token) {
  const config = {
    method: 'post',
    url: '/users',
    data: UserData,
    headers: {
      Authorization: token,
    },
  };
  return HTTP(config);
};
////////////////////////////
export const requestOtp = function (otp) {
  const config = {
    method: 'post',
    url: '/auth/otp',
    data: otp,
    headers: {
      'x-protocol-version': 'v3',
    },
  };
  return HTTP(config);
};
////////////////////////////
export const validateOtp = function (otp) {
  const config = {
    method: 'post',
    url: '/auth/otp/validate',
    data: otp,
  };
  return HTTP(config);
};
////////////////////////////
export const validateData = function (otp) {
  const config = {
    method: 'post',
    url: '/auth/validate_data',
    data: otp,
  };
  return HTTP(config);
};
//validateID
export const validateID = function (body) {
  const config = {
    method: 'post',
    url: '/auth/validate_id',
    data: body,
    headers: {
      'x-protocol-version': 'v3',
    },
  };
  return HTTP(config);
};
////////////////////////////
export const userChangePassword = function (password, token) {
  const config = {
    method: 'put',
    url: '/passwords',
    data: password,
    headers: {
      Authorization: token,
    },
  };

  return HTTP(config);
};
////////////////////////////
export const socialLogin = (provider, token) => {
  let data = {
    social_auth: {
      provider: provider,
      credentials: {
        token: token,
      },
    },
  };
  const config = {
    method: 'post',
    url: '/auth/social',
    data: data,
  };
  return HTTP(config);
};
////////////////////////////
export const socialSignup = (provider, socialToken, authorizationToken) => {
  let data = {
    social_auth: {
      provider: provider,
      credentials: {
        token: socialToken,
      },
    },
  };

  const config = {
    method: 'post',
    url: '/users/social',
    data: data,
    headers: {
      Authorization: authorizationToken,
    },
  };
  return HTTP(config);
};
////////////////////////////
export const validateSocial = (
  provider,
  socialToken,
  authorizationToken,
  otp,
  mobile_number
) => {
  let data = {
    otp: otp,
    mobile_number: mobile_number,
    social_auth: {
      uid: '',
      provider: provider,
      credentials: {
        token: socialToken,
        secret: '',
      },
    },
  };

  const config = {
    method: 'post',
    url: '/auth/otp/validate_social',
    data: data,
    headers: {
      Authorization: authorizationToken,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const getUserPlans = function () {
  const config = {
    method: 'get',
    url: '/users/plan',
  };
  return HTTP(config);
};
//////////////////////////////
export const switchPlan = (destinationPlanId) => {
  const config = {
    method: 'put',
    url: '/users/switch-plan',
    data: {
      plan_id: destinationPlanId,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const stopAutoRenewal = () => {
  const config = {
    method: 'post',
    url: '/users/plan/stop-auto-renewal',
  };
  return HTTP(config);
};
//////////////////////////////
export const startAutoRenewal = () => {
  const config = {
    method: 'post',
    url: '/users/plan/start-auto-renewal',
  };
  return HTTP(config);
};
//////////////////////////////

export const transferBalance = (amount, toMobile, otp) => {
  const config = {
    method: 'post',
    url: '/users/transfer-balance-new',
    data: {
      amount: amount,
      to_mobile: toMobile,
      otp: otp,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const sendEmailOtp = (email) => {
  const config = {
    method: 'patch',
    url: '/users/current/email',
    data: {
      email: email,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const confirmEmailOtp = (otp) => {
  const config = {
    method: 'post',
    url: '/users/current/email/confirm',
    data: {
      otp: otp,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const updatePassword = (oldPass, newPass) => {
  const config = {
    method: 'put',
    url: '/users/current/password',
    data: {
      old_password: oldPass,
      new_password: newPass,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const setCommunicationLanguage = (langId) => {
  let url = `/users/current/sim/languages`;
  const config = {
    method: 'put',
    url: url,
    data: {
      language_id: langId,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const enabledService = (serviceKey, operation) => {
  let url = `/users/current/sim/settings`;
  const config = {
    method: 'put',
    url: url,
    data: {
      key: serviceKey,
      operation: operation,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const getSimInfo = () => {
  let url = `/users/current/sim/info`;
  const config = {
    method: 'get',
    url: url,
  };
  return HTTP(config);
};
//////////////////////////////
export const getReasonList = () => {
  let url = `/users/current/sim-deactivation/reasons`;
  const config = {
    method: 'get',
    url: url,
  };
  return HTTP(config);
};
//////////////////////////////
export const unblockNumber = (countryCode, msisdn) => {
  let url = `/users/current/sim/unblockNumber`;
  const config = {
    method: 'put',
    url: url,
    data: {
      blockMSISDN: msisdn,
      countryCode: countryCode,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const blockNumber = (countryCode, msisdn) => {
  let url = `/users/current/sim/blockNumber`;
  const config = {
    method: 'put',
    url: url,
    data: {
      blockMSISDN: msisdn,
      countryCode: countryCode,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const blockPromotionalCalls = (allow, exceptLebara) => {
  let url = `/users/current/sim/blockPromoCalls`;
  const config = {
    method: 'post',
    url: url,
    data: {
      allow: allow,
      exceptLebara: exceptLebara,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const getBlockedNumbersList = () => {
  let url = `/users/current/sim/blockedNumbersList`;
  const config = {
    method: 'get',
    url: url,
  };
  return HTTP(config);
};
//////////////////////////////
export const getBlockCountries = () => {
  let url = `/users/current/sim/simBlockCountryList`;
  const config = {
    method: 'get',
    url: url,
  };
  return HTTP(config);
};
//////////////////////////////
export const getAllBlockCountryList = () => {
  let url = `/users/current/sim/allBlockCountryList`;
  const config = {
    method: 'get',
    url: url,
  };
  return HTTP(config);
};
//////////////////////////////
export const blockCountry = (offer_id) => {
  let url = `/users/current/sim/blockCountry`;
  const config = {
    method: 'put',
    url: url,
    data: {
      offerId: offer_id,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const deleteExistingBlockCountry = (offer_id) => {
  let url = `/users/current/sim/deleteBlockCountryOffer`;
  const config = {
    method: 'put',
    url: url,
    data: {
      offerId: offer_id,
    },
  };
  return HTTP(config);
};
//////////////////////////////
export const transferDataBalance = (walletId, denominationId, toMobile) => {
  const config = {
    method: 'post',
    url: '/users/data/transfer',
    data: {
      denomination_id: denominationId,
      wallet_id: walletId,
      to_mobile: toMobile,
    },
  };
  return HTTP(config);
};
//////////////////////////////

export const getWallets = () => {
  const config = {
    method: 'get',
    url: '/users/data/wallets',
  };
  return HTTP(config);
};
////////////////////////////////
export const getTransferableDenominations = () => {
  const config = {
    method: 'get',
    url: '/users/data/transferable-denominations',
  };
  return HTTP(config);
};
////////////////////////////////
export const getResolveRecipient = (phone) => {
  const data = {
    to_mobile: phone,
  };

  const config = {
    method: 'post',
    url: '/users/balance/international-transfer/resolve-recipient',
    data: data,
  };
  return HTTP(config);
};
///////////////////////////
export const transferCreditInternational = (
  otp,
  to_mobile,
  service_id,
  operator_id,
  product_id
) => {
  const data = {
    verify: {
      otp: otp,
    },
    transfer: {
      to_mobile: to_mobile,
      service_id: service_id,
      operator_id: operator_id,
      product_id: product_id,
    },
  };
  const config = {
    method: 'post',
    url: '/users/balance/international-transfer/transfer',
    data: data,
  };
  return HTTP(config);
};

export const getUsageHistory = (
  type = 'calls',
  page = 1,
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/usage/history/${type}?page=${page}&per_page=${per_page}`,
  };

  return HTTP(config);
};

export const getChartUsageHistory = (
  type = 'calls',
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/usage/overview/${type}?per_page=${per_page}`,
  };

  return HTTP(config);
};

export const getChartRechargeHistory = (
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/recharge/history/overview?per_page=${per_page}`,
  };

  return HTTP(config);
};

export const getChartTransferHistory = (
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/transfer/overview?per_page=${per_page}`,
  };

  return HTTP(config);
};

export const getRechargeHistory = (
  page = 1,
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/recharge/history?page=${page}&per_page=${per_page}`,
  };

  return HTTP(config);
};
export const getTransferHistory = (
  page = 1,
  per_page = Settings.historyTransactionsPerPage
) => {
  const config = {
    method: 'get',
    url: `/users/current/transfer/history?page=${page}&per_page=${per_page}`,
  };

  return HTTP(config);
};

export const getUserBills = () => {
  const config = {
    method: 'get',
    url: `/postpaid/overview`,
  };
  return HTTP(config);
};

export const getBillDetails = (invoiceId) => {
  const config = {
    method: 'get',
    url: `/postpaid/invoices/${invoiceId}`,
  };
  return HTTP(config);
};

export const getInvoiceServiceUsage = (invoiceId, serviceId) => {
  const config = {
    method: 'get',
    url: `/postpaid/invoices/${invoiceId}/services/${serviceId}`,
  };
  return HTTP(config);
};

export const getInvoiceDocument = (invoiceId) => {
  const config = {
    method: 'get',
    url: `/postpaid/invoices/${invoiceId}/file`,
  };
  return HTTP(config);
};
//////////////////////////////
export const deactivateNumber = (data) => {
  const config = {
    method: 'post',
    url: '/users/current/deactivate-number',
    data: {
      reasonKey: data.reasonKey,
      otp: data.otp,
    },
  };
  return HTTP(config);
};
