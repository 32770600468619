import * as actions from './actionsType';
import languages from '../../languages.json';

const initialState = {}
initialState['currentRoute'] = null;
initialState['prevRoute'] = null;
initialState['loading'] = false;
initialState['routes'] = null;
initialState['redirects'] = null;
initialState['configs'] = null;
initialState['urlState'] = null;
initialState['translation'] = null;
initialState['pageContent'] = null;
initialState['bundleRedirect'] = null;

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SEO_SUCCESS:
      return {
        ...state,
        pageContent: action.pageContent,
      };

    case actions.SET_PAGE_CONTENT_EMPTY:
    case actions.GET_SEO_FAILED:
      return {
        ...state,
        pageContent: null,
      };

    case actions.SET_ESIM_FLOW:
      return {
        ...state,
        isEsimFlow: action.isEsimFlow,
      };

    case actions.SET_URL_STATE:
      return {
        ...state,
        urlState: action.urlState,
      };

    case actions.SET_TRANSLATION_KEYS:
      return {
        ...state,
        translation: action.translation,
      };
    case actions.FETCHING_LAYOUT_CONFIGRATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actions.SET_LAYOUT_CONFIGRATION:
      const language = languages[action.configrations.language];
      return {
        ...state,
        iso: language.iso,
        label: language.name,
        direction: language.direction,
        left: language.left,
        right: language.right,
        font_family: language?.font_family,
        routes: action?.configrations?.webRoutes?.routes,
        redirects: action?.configrations?.webRoutes?.redirects,
        loading: false,
        configs: action?.configrations?.appConfigrations,
        translation: action?.configrations?.translation,
      };
    case actions.SET_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    case actions.SET_BUNDLE_REDIRECT_ROUTES:
      return {
        ...state,
        bundleRedirect: action.redirect,
      };
    case actions.REMOVE_BUNDLE_CURRENT_ROUTE:
      return {
        ...state,
        bundleRedirect: null,
      };
    case actions.SET_CURRENT_ROUTE:
      return {
        ...state,
        prevRoute:
          state.currentRoute === null ? null : { ...state.currentRoute },
        currentRoute: {
          ...action.route,
          routeLang: state.iso,
          routeUrl: window.location.pathname,
        },
      };
    case actions.START_FETCHING_ROUTES:
    case actions.START_FETCHING_LAYOUT_CONFIGRATION:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCHING_ROUTES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actions.SET_CONFIGRATIONS:
      return {
        ...state,
        configs: action?.configs,
      };
    default:
      return state;
  }
};
export default layoutReducer;
