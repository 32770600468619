import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Settings from 'services/settings.service';
import { ICONS } from './icons';
import keycode from 'keycode';
import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  GET_URL_PATH_FIRST_PART,
  GET_URL_PATH_LAST_PART,
} from '../constants';

export const gbToMb = (val) => {
  return val * 1024;
};

export const getAllUrlState = () => {
  return window.history.state ? window.history.state.state : null;
};
export const setUrlState = (state, key, value) => {
  let newState = '';
  if (state) {
    newState = { ...state };
    newState[key] = value;
    return newState;
  }
  newState = {};
  newState[key] = value;
  return newState;
};

export const arrayToKeyedObject = (arr, key) => {
  let obj = {};
  for (let item of arr) {
    let templateElement = obj[item[key]];

    if (templateElement && Array.isArray(templateElement)) {
      templateElement.push(item);
    } else if (templateElement && !Array.isArray(templateElement)) {
      templateElement = [templateElement, item];
    } else {
      templateElement = item;
    }
    obj[item[key]] = templateElement;
  }
  return obj;
};

export const isMobile = (checkSize = 1024) => {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return w <= checkSize;
};
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
export const urlFormatter = (url) => {
  const initUrl = '/';

  if (!url) {
    return '';
  }

  if (url.substring(0, 1) === initUrl) {
    return !window.appLang ? `/en${url}` : `/${window.appLang}${url}`;
  } else {
    return !window.appLang ? `/en/${url}` : `/${window.appLang}/${url}`;
  }
};
export const onlyDigits = (event) => {
  if (
    !(
      keycode(event.which) === '0' ||
      keycode(event.which) === '1' ||
      keycode(event.which) === '2' ||
      keycode(event.which) === '3' ||
      keycode(event.which) === '4' ||
      keycode(event.which) === '5' ||
      keycode(event.which) === '6' ||
      keycode(event.which) === '7' ||
      keycode(event.which) === '8' ||
      keycode(event.which) === '9' ||
      keycode(event.which) === 'numpad 0' ||
      keycode(event.which) === 'numpad 1' ||
      keycode(event.which) === 'numpad 2' ||
      keycode(event.which) === 'numpad 3' ||
      keycode(event.which) === 'numpad 4' ||
      keycode(event.which) === 'numpad 5' ||
      keycode(event.which) === 'numpad 6' ||
      keycode(event.which) === 'numpad 7' ||
      keycode(event.which) === 'numpad 8' ||
      keycode(event.which) === 'numpad 9' ||
      keycode(event.which) === 'backspace' ||
      keycode(event.which) === 'delete' ||
      keycode(event.which) === 'tab' ||
      (event.metaKey &&
        (keycode(event.which) !== 'v' || keycode(event.which) !== 'c')) ||
      (event.ctrlKey &&
        (keycode(event.which) !== 'v' || keycode(event.which) !== 'c'))
    )
  ) {
    event.preventDefault();
  }
};

export const validateDataNumber = (dataNumber) => {
  const pattern = Settings.format.dataNumberPattern.rule;
  return pattern.test(dataNumber);
};

export const validatePatterns = (obj, phoneNumber) => {
  let pattern = '';
  let numberLength = phoneNumber.length;
  if (numberLength === 9) {
    pattern = obj.length9;
  } else if (numberLength === 10) {
    pattern = obj.length10;
  } else if (numberLength === 12) {
    pattern = obj.length12;
  } else if (numberLength === 14) {
    pattern = obj.length14;
  } else {
    return false;
  }
  return pattern.test(phoneNumber);
};

export const validateNumber = (phoneNumber, local = 'all') => {
  let result = '';
  if (local === 'all') {
    result = validatePatterns(Settings.format.saudiMobileKeys, phoneNumber);
  } else {
    result = validatePatterns(Settings.format.lebaraMobileKeys, phoneNumber);
  }
  return result;
};
export const deepCopy = (obj) => {
  let newObj = {};
  let objKeys = Object.keys(obj);
  for (let key of objKeys) {
    if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        newObj[key] = obj[key].slice();
      } else {
        newObj[key] = { ...obj[key] };
      }
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const startWithPattern = (url, pattern) => {
  let urlLength = url.length;
  let patternLength = pattern.length;

  if (urlLength < patternLength) return false;
  return url.substring(0, patternLength) === pattern;
};

export const checkExternalLink = (url) => {
  return startWithPattern(url, 'http');
};

export const generateLink = (
  url,
  classes,
  title,
  key = -1,
  action = null,
  hasArrow = null
) => {
  if (!url) {
    return (
      <a className={classes} key={key} onClick={action}>
        {title} {hasArrow}
      </a>
    );
  }
  let showValues = false;
  if (title === 'Our Values' || title === 'قيم ليبارا') {
    showValues = true;
  }

  let lnk = url;
  let internalLink = false;
  if (!startWithPattern(url, 'http')) {
    internalLink = true;
    lnk = urlFormatter(url);
  }

  if (internalLink) {
    const searchParams = lnk.split('?');
    return (
      <Link
        to={{
          pathname: searchParams[0],
          state: showValues,
          search: searchParams[1] ? '?' + searchParams[1] : '',
        }}
        className={classes}
        key={key}
        onClick={action}
      >
        {title} {hasArrow}
      </Link>
    );
  }
  return (
    <a
      href={lnk}
      className={classes}
      target="_blank"
      key={key}
      rel="noopener noreferrer nofollow"
    >
      {title} {hasArrow}
    </a>
  );
};

export const inputClasses = (elem, defaultClasses, state) => {
  let elemValid = true;
  let newClasses = [...defaultClasses];
  if (state[elem]?.touched) {
    elemValid = state[elem]['validation'];
    if (state[elem]['value']?.length > 0) {
      newClasses.push('touched');
    }
    if (!elemValid) {
      newClasses.push('error');
    }
  }
  return newClasses;
};

// datepart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
export const dateDiff = (datepart, fromdate, todate) => {
  datepart = datepart.toLowerCase();
  let diff = todate - fromdate;
  let divideBy = {
    w: 604800000,
    d: 86400000,
    h: 3600000,
    n: 60000,
    s: 1000,
  };

  return Math.floor(diff / divideBy[datepart]);
};

export const categoryIcon = (category) => {
  switch (category) {
    case 'data':
      return ICONS['tap_and_play'];
    case 'minutes':
      return ICONS['phone24'];
    case 'sms':
      return ICONS['envelope'];
    default:
      return 'error';
  }
};

export const getPageSeo = (seoTags) => {
  if (seoTags) {
    const metas = seoTags['meta'].map((meta) => {
      if (meta.name) {
        return <meta key={meta.name} name={meta.name} content={meta.content} />;
      } else if (meta.property) {
        const metaContent =
          meta.property === 'og:url' ? window.location.href : meta.content;
        return (
          <meta
            key={meta.property}
            property={meta.property}
            content={metaContent}
          />
        );
      }
      return null;
    });
    const title = <title>{seoTags.title}</title>;
    return {
      meta: metas,
      title: title,
    };
  }
  return null;
};
export const getQueries = (query) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(query);
};

export const mergingHistoryRecords = (mergeRecords) => {
  const mergedArray = {};
  mergeRecords.forEach((rechargeItemRow) => {
    if (mergedArray[rechargeItemRow.date]) {
      mergedArray[rechargeItemRow.date]['transactions'] = mergedArray[
        rechargeItemRow.date
      ]['transactions'].concat(rechargeItemRow['transactions']);
    } else {
      mergedArray[rechargeItemRow.date] = {};
      mergedArray[rechargeItemRow.date]['transactions'] =
        rechargeItemRow['transactions'];
    }
  });

  return Object.keys(mergedArray).map((item) => {
    return { date: item, transactions: mergedArray[item]['transactions'] };
  });
};

export const sortHistory = (historyArray, sort = SORT_ASCENDING) => {
  return historyArray.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    if (sort === SORT_ASCENDING && typeof a === 'object') {
      return new Date(b.date) - new Date(a.date);
    } else if (sort === SORT_DESCENDING && typeof a === 'object') {
      return new Date(a.date) - new Date(b.date);
    } else if (sort === SORT_ASCENDING) {
      return new Date(b) - new Date(a);
    } else {
      return new Date(a) - new Date(b);
    }
  });
};

export const standardFormatDate = (date) => {
  let orderDateYear = new Date(date).getFullYear();
  let orderDateMonth = new Date(date).getUTCMonth() + 1;
  let orderDateDay = new Date(date).getUTCDate();
  return orderDateDay + '/' + orderDateMonth + '/' + orderDateYear;
};

export const formatTime = (date) => {
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const isEmail = (email) => {
  return Settings.format.email.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  return (
    Settings.format.phoneNumberPattern.test(phoneNumber) ||
    Settings.format.dataNumberPattern.rule.test(phoneNumber)
  );
};

export const preventSpace = (event) => {
  if (event.keyCode === keycode('space')) {
    event.preventDefault();
  }
};

export const handleError = (error) => {
  let errorMsg = error;
  if (error.response && error.response.data) {
    errorMsg = error.response.data.message;
  }
  return errorMsg;
};

export const getPath = (
  path = window.location.pathname,
  part = GET_URL_PATH_LAST_PART
) => {
  let newPath = path.substr(4);
  if (part === GET_URL_PATH_FIRST_PART) {
    const indexOfBackSlash = newPath.indexOf('/');
    if (indexOfBackSlash !== -1) {
      return newPath.substring(0, indexOfBackSlash);
    }
    return newPath;
  } else {
    ///GET_URL_PATH_LAST_PART
    if (newPath.lastIndexOf('/') === newPath.length - 1) {
      return newPath.substr(newPath.lastIndexOf('/') + 1, newPath.length - 1);
    }
    return newPath.substr(newPath.lastIndexOf('/') + 1);
  }
};

export const validateNumbersWithNumpad = (key) => {
  return (
    key === '0' ||
    key === '1' ||
    key === '2' ||
    key === '3' ||
    key === '4' ||
    key === '5' ||
    key === '6' ||
    key === '7' ||
    key === '8' ||
    key === '9' ||
    key === 'numpad 0' ||
    key === 'numpad 1' ||
    key === 'numpad 2' ||
    key === 'numpad 3' ||
    key === 'numpad 4' ||
    key === 'numpad 5' ||
    key === 'numpad 6' ||
    key === 'numpad 7' ||
    key === 'numpad 8' ||
    key === 'numpad 9' ||
    key === 'backspace' ||
    key === 'delete'
  );
};

export const loadJS = (src, id) => {
  var ref = window.document.getElementsByTagName('script')[0];
  var script = window.document.createElement('script');
  script.src = src;
  script.id = id;
  script.async = true;
  if (id === 'chatWidget') {
    script.setAttribute(
      'pureconnect-widgets-data',
      'eyJpZCI6IkxlYmFyYUNoYXQiLCJpY1NlcnZlcnMiOlsiMTcyLjI1LjMuODIiXSwicmV2ZXJzZVByb3h5VXJsIjoiaHR0cHM6Ly9nZW5lc3lzLmV0aXNhbC5jb20uc2EvYXBpIiwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ=='
    );
  }
  ref.parentNode.insertBefore(script, ref);
};

export const addJS = (src, id) => {
  if (!document.getElementById('id')) {
    loadJS(src, id);
  }
};

export function formatVat(number) {
  const isWholeNumber = number % 1 === 0;
  return isWholeNumber ? number.toFixed(0) : number.toFixed(2);
}

export const formatNumberWithZeros = (num) => {
  let number = Number(num);
  if (Number.isInteger(number)) {
    return number.toString();
  } else if (typeof number === 'number') {
    const formattedNumber = number.toString();
    return formattedNumber.replace(/^0\./, '0.');
  } else {
    return number;
  }
};
