export const SET_MAP_LOADED = 'SET_MAP_LOADED';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_FETCHING_CURRENT_POSITION = 'SET_FETCHING_CURRENT_POSITION';
export const SET_CURRENT_CORDINATE = 'SET_CURRENT_CORDINATE';

export const FETCHING_STORES_START = 'FETCHING_STORES_START';
export const FETCHING_STORES_SUCCESS = 'FETCHING_STORES_SUCCESS';
export const FETCHING_STORES_FAILED = 'FETCHING_STORES_FAILED';

export const FETCHING_FILTERS_START = 'FETCHING_FILTERS_START';
export const FETCHING_FILTERS_SUCCESS = 'FETCHING_FILTERS_SUCCESS';
export const FETCHING_FILTERS_FAILED = 'FETCHING_FILTERS_FAILED';

export const UPDATE_ACTIVE_FILTERS = 'UPDATE_ACTIVE_FILTERS';

export const SELECT_STORE = 'SELECT_STORE';
export const UN_SELECT_STORE = 'UN_SELECT_STORE';

export const CHANGE_VIEW = 'CHANGE_VIEW';


export const SEARCH_RESULT_START = 'SEARCH_RESULT_START';
export const SEARCH_RESULT_SUCCESS = 'SEARCH_RESULT_SUCCESS';
export const SEARCH_RESULT_FAILED = 'SEARCH_RESULT_FAILED';
