export const SET_MENU = 'SET_MENU';

export const START_FETCHING_MAIN_MENU = 'START_FETCHING_MAIN_MENU';
export const FETCHING_MAIN_MENU_FAILED = 'FETCHING_MAIN_MENU_FAILED';

export const START_FETCHING_LAST_MENU = 'START_FETCHING_LAST_MENU';
export const FETCHING_LAST_MENU_FAILED = 'FETCHING_LAST_MENU_FAILED';

export const START_FETCHING_FOOTER_MENU = 'START_FETCHING_FOOTER_MENU';
export const FETCHING_FOOTER_MENU_FAILED = 'FETCHING_FOOTER_MENU_FAILED';
