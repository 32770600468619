import HTTP from './http.service';
class Configration {

  static getRoutes(lang) {
    const config = {
      method: 'get',
      url: '/configuration/website_routes'
    };
    return HTTP(config);
  }
  static getConfigrations() {
    const config = {
      method: 'get',
      url: '/configuration/app'
    };
    return HTTP(config);
  }

  static bulkInitialize() {
    const config = {
      method: 'get',
      url: '/configuration/bulk-initialize'
    };
    return HTTP(config);
  }

}

export default Configration;
