export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_BUNDLE_CURRENT_ROUTE = 'SET_BUNDLE_CURRENT_ROUTE';
export const REMOVE_BUNDLE_CURRENT_ROUTE = 'REMOVE_BUNDLE_CURRENT_ROUTE';

export const START_FETCHING_ROUTES = 'START_FETCHING_ROUTES';
export const FETCHING_ROUTES_FAILED = 'FETCHING_ROUTES_FAILED';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_BUNDLE_REDIRECT_ROUTES = 'SET_BUNDLE_REDIRECT_ROUTES';

export const SET_CONFIGRATIONS = 'SET_CONFIGRATIONS';
export const SET_URL_STATE = 'SET_URL_STATE';
export const GET_URL_STATE = 'GET_URL_STATE';

export const SET_ESIM_FLOW = 'SET_ESIM_FLOW';
export const SET_POSTPAID_FLOW = 'SET_POSTPAID_FLOW';
export const SET_LAYOUT_CONFIGRATION = 'SET_LAYOUT_CONFIGRATION';
export const START_FETCHING_LAYOUT_CONFIGRATION =
  'START_FETCHING_LAYOUT_CONFIGRATION';
export const FETCHING_LAYOUT_CONFIGRATION_FAILED =
  'FETCHING_LAYOUT_CONFIGRATION_FAILED';
export const SET_TRANSLATION_KEYS = 'SET_TRANSLATION_KEYS';

export const GET_SEO_START = 'GET_SEO_START';
export const GET_SEO_SUCCESS = 'GET_SEO_SUCCESS';
export const GET_SEO_FAILED = 'GET_SEO_FAILED';
export const SET_PAGE_CONTENT_EMPTY = 'SET_PAGE_CONTENT_EMPTY';

export const UPDATE_CONFIG_DATA = 'UPDATE_CONFIG_DATA';
