export const FETCHING_CATEGORIES_START = 'FETCHING_CATEGORIES_START';
export const FETCHING_CATEGORIES_SUCCESS = 'FETCHING_CATEGORIES_SUCCESS';
export const FETCHING_CATEGORIES_FAILED = 'FETCHING_CATEGORIES_FAILED';

export const FETCHING_PRODUCTS_START = 'FETCHING_PRODUCTS_START';
export const FETCHING_PRODUCTS_SUCCESS = 'FETCHING_PRODUCTS_SUCCESS';
export const FETCHING_PRODUCTS_FAILED = 'FETCHING_PRODUCTS_FAILED';

export const FETCHING_VANINTIES_START = 'FETCHING_VANINTIES_START';
export const FETCHING_VANINTIES_SUCCESS = 'FETCHING_VANINTIES_SUCCESS';
export const FETCHING_VANINTIES_FAILED = 'FETCHING_VANINTIES_FAILED';

export const SET_VANINTIES = 'SET_VANINTIES';

export const SELECTED_NUMBER = 'SELECTED_NUMBER';
export const SELECTED_PLAN = 'SELECTED_PLAN';

export const SET_ORDER_SESSION_ID = 'SET_ORDER_SESSION_ID';


export const GET_SHOP_WIDGETS_START = 'GET_SHOP_WIDGETS_START';
export const GET_SHOP_WIDGETS_SUCCESS = 'GET_SHOP_WIDGETS_SUCCESS';
export const GET_SHOP_WIDGETS_FAILED = 'GET_SHOP_WIDGETS_FAILED';


export const GET_SHOP_CATEGORY_START = 'GET_SHOP_CATEGORY_START';
export const GET_SHOP_CATEGORY_SUCCESS = 'GET_SHOP_CATEGORY_SUCCESS';
export const GET_SHOP_CATEGORY_FAILED = 'GET_SHOP_CATEGORY_FAILED';
