import * as actions from './actionsType';
import * as homepageActions from '../Homepage/actionsType';
import Configration from 'services/configration.service';
import Translation from 'services/translation.service';
import { arrayToKeyedObject } from '../../util';
import { getPageContent } from 'services/content.service';

import { initialUserDashboard } from 'stores/Dashboard/dashboardActions';
import store from 'stores/RootReducer/rootReducer';
import { setMenu } from '../Menu/menuActions';

export const setRoute = (route) => {
  return { type: actions.SET_CURRENT_ROUTE, route: route };
};

export const setLayoutConfigrationsFailed = () => {
  return { type: actions.FETCHING_LAYOUT_CONFIGRATION_FAILED };
};

const buildRoutes = (lang, routes) => {
  let newRoutes = [];
  let redirects = [];
  let resultLength = routes.length;
  if (lang !== 'en') {
    for (let i = 0; i < resultLength; i++) {
      let templateString = routes[i].template;
      let key;
      if (templateString) {
        key = templateString.substring(0, templateString.lastIndexOf('-'));
      }
      routes[i]['template'] = key;
      if (!routes[i].rewrite_url) {
        // its mean this is a root route not redirect
        newRoutes.push(routes[i]);
      } else {
        redirects.push(routes[i]);
      }
    }
  } else {
    for (let i = 0; i < resultLength; i++) {
      if (!routes[i].rewrite_url) {
        // its mean this is a root route not redirect
        newRoutes.push(routes[i]);
      } else {
        redirects.push(routes[i]);
      }
    }
  }

  return [newRoutes, redirects];
};

export const setUrlState = (urlState) => {
  return { type: actions.SET_URL_STATE, urlState: urlState };
};

export const getTranslation = (lang, dispatch) => {
  return Translation.getTranslation();
};

export const setTranslationKeys = (keys) => {
  return (dispatch) => {
    dispatch({ type: actions.SET_TRANSLATION_KEYS, translation: keys });
  };
};
export const setBundlRedirect = (route) => {
  return (dispatch) => {
    dispatch({ type: actions.SET_BUNDLE_REDIRECT_ROUTES, redirect: route });
  };
};
export const removeBundleRedirect = () => {
  return (dispatch) => {
    dispatch({ type: actions.REMOVE_BUNDLE_CURRENT_ROUTE });
  };
};

export const saveLayoutConfigrations = (configrations) => {
  return {
    type: actions.SET_LAYOUT_CONFIGRATION,
    configrations: configrations,
  };
};

export const startLayoutConfigrations = () => {
  return { type: actions.START_FETCHING_LAYOUT_CONFIGRATION };
};

export const checkUserLoggedin = () => {
  return store.getState().user.loggedIn;
};

export const setLayoutConfigrations = (
  lang,
  urlState = null,
  bundleRedirect
) => {
  let isNotChecked = true;
  window.appLang = lang;
  document.body.className = lang;
  return (dispatch) => {
    // save states in the url to redux
    if (urlState !== null) {
      dispatch(setUrlState(urlState));
    }

    dispatch(startLayoutConfigrations());

    /* 
      if user logged in then re-initiate dashboard 
      to update plans and dashbaord 
      after switching language 
    */
    store.subscribe(() => {
      if (checkUserLoggedin() && isNotChecked) {
        isNotChecked = false;
        store.dispatch(initialUserDashboard());
      }
    });
    dispatch(bulkInitialize(lang));
  };
};

export const updateEsimFlow = (isEsimFlow) => {
  return { type: actions.SET_ESIM_FLOW, isEsimFlow: isEsimFlow };
};

export const updatePostpaidFlow = (isPostpaidFlow) => {
  return { type: actions.SET_POSTPAID_FLOW, isPostpaidFlow: isPostpaidFlow };
};

export const updateLayout = (layout) => {
  return { type: actions.SET_LAYOUT, layout: layout };
};

export const saveConfigrations = (configs) => {
  return { type: actions.SET_CONFIGRATIONS, configs: configs };
};

export const getConfigrations = () => {
  return (dispatch) => {
    Configration.getConfigrations()
      .then((response) => {
        dispatch(saveConfigrations(response.data));
      })
      .catch((error) => {});
  };
};

export const bulkInitialize = (lang) => {
  return (dispatch, getState) => {
    Configration.bulkInitialize(lang)
      .then((response) => {
        const result = response?.data?.result;
        const [newRoutes, redirects] = buildRoutes(lang, result?.routes);
        const routesObject = arrayToKeyedObject(newRoutes, 'template');
        const redirectsObject = arrayToKeyedObject(redirects, 'route');
        dispatch(
          saveLayoutConfigrations({
            language: lang,
            webRoutes: {
              routes: routesObject,
              redirects: redirectsObject,
            },
            appConfigrations: result.settings,
            translation: result.translation,
          })
        );

        let packagesData = result?.packages?.map((item) => ({
          ...item,
          img: item.desktop_image,
          desc: item.details,
        }));

        dispatch({
          type: homepageActions.FETCHING_HOMEPAGE_PACKAGES_SUCCESS,
          packages: packagesData,
        });

        dispatch(setMenu('main-menu', result?.mainMenu));
        dispatch(setMenu('footer-menu', result?.footerMenu));
        dispatch(setMenu('footer-last', result?.footerLast));

        dispatch({
          type: homepageActions.FETCHING_HOMEPAGE_SECTIONS_SUCCESS,
          sections: result?.homepage,
        });
      })
      .catch((error) => {
        const invalidSessionID = -15;
        if (
          error?.response?.data?.code &&
          error.response.data.code !== invalidSessionID
        ) {
          dispatch(setLayoutConfigrationsFailed());
        }
      });
  };
};

/////////////////////////
export const getPageContentBySlug = (slug) => {
  return (dispatch) => {
    dispatch({ type: actions.GET_SEO_START });

    getPageContent(slug)
      .then((response) => {
        dispatch({
          type: actions.GET_SEO_SUCCESS,
          pageContent: response.data.result,
        });
      })
      .catch((error) => {
        dispatch({ type: actions.GET_SEO_FAILED });
      });
  };
};
/////////////////////////
export const setPageContentEmpty = () => {
  return (dispatch) => {
    dispatch({ type: actions.SET_PAGE_CONTENT_EMPTY });
  };
};
