import * as actions from './actionsType';

let initialState = {
  proprties: null,
  balance: null,
  consumption: [],
  additional_widgets: [],
  loading: false,
  userPlans: null,
  subscriptionType: null
};

let dashboardReducer = (state = initialState, action) => {
  // alert('action.type= ' + action.type)
  switch (action.type) {

    case actions.DASHBOARD_SUCCESS:

      return {
        ...state,
        error: null,
        loading: false,
        proprties: action.proprties,
        balance: action.balance,
        consumption: action.consumption,
        additional_widgets: action.additional_widgets,
      };
    case actions.DASHBOARD_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.DASHBOARD_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };


    case actions.USER_PLANS_SUCCESS:
      return {
        ...state,
        userPlans: action.userPlans,
      };

    case actions.USER_PLANS_FAILED:
      return {
        ...state,
        userPlans: null,
        error: action.errorMsg,
        loading: false
      }

    case actions.INITIAL_USER_DASHBOARD_START:
      return {
        ...state,
        loading: true,
      }
    case actions.INITIAL_USER_DASHBOARD_SUCCESS:
      return {
        ...state,
        proprties: action.proprties,
        balance: action.balance,
        consumption: action.consumption,
        additional_widgets: action.additional_widgets,
        userPlans: action.userPlans,
        subscriptionType: action.subscriptionType,
        loading: false,
      }

    case actions.USER_PLANS_RESET:
      return {
        proprties: null,
        balance: null,
        consumption: [],
        additional_widgets: [],
        loading: false,
        userPlans: null,
        subscriptionType: null
      }


    default:
      return state;
  }
};
export default dashboardReducer;
