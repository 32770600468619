import * as actions from './actionsType';

let initialState = {
  map_loaded: {},
  user_location: null,
  fetchingCurrentPosition: false,
  currentCordinate: null,
  filters: null,
  activeFilters: null,
  stores: null,
  error: null,
  loading: false,
  filtersLoading: false,
  selectedStore: null,
  view: 'list',

  currentPage: 1,
  perPage: 10,
};

let mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MAP_LOADED:
      let map_loaded = {};
      let mapLoadedKeys = Object.keys(state.map_loaded),
        mapLoadedKeysLength = mapLoadedKeys.length;
      for (let i = 0; i < mapLoadedKeysLength; i++) {
        map_loaded[mapLoadedKeys[i]] = true;
      }
      map_loaded[action.mapId] = true;
      return {
        ...state,
        map_loaded: map_loaded,
        // map_loaded: { [action.mapId]: true }
      };
    case actions.SET_USER_LOCATION:
      return {
        ...state,
        user_location: action.location,
      };
    case actions.SET_FETCHING_CURRENT_POSITION:
      return {
        ...state,
        fetchingCurrentPosition: action.value,
      };
    case actions.SET_CURRENT_CORDINATE:
      return {
        ...state,
        currentCordinate: action.value,
      };
    case actions.FETCHING_STORES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.FETCHING_STORES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        stores: action.stores,
      };
    case actions.FETCHING_STORES_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };

    case actions.FETCHING_FILTERS_START:
      return {
        ...state,
        error: null,
        filtersLoading: true,
      };
    case actions.FETCHING_FILTERS_SUCCESS:
      return {
        ...state,
        error: null,
        filtersLoading: false,
        filters: action.filters,
        activeFilters: action.activeFilters,
      };
    case actions.FETCHING_FILTERS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        filtersLoading: false,
      };

    case actions.UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: action.activeFilters,
        selectedStore: null,
      };
    case actions.SELECT_STORE:
      return {
        ...state,
        selectedStore: action.selectedStore,
        view: 'map',
      };
    case actions.UN_SELECT_STORE:
      return {
        ...state,
        selectedStore: null,
      };

    case actions.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    // if (action.view === 'list') {
    //   return {
    //     ...state,
    //     view: action.view,
    //     selectedStore: null
    //   };

    // }
    // else {
    //   return {
    //     ...state,
    //     view: action.view
    //   };
    // }

    case actions.SEARCH_RESULT_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        stores: action.result,
      };
    case actions.SEARCH_RESULT_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };

    default:
      return state;
  }
};
export default mapReducer;
