import * as actions from './actionsType';

let initialState = {
  sliders: [],
  sliderLoading: false,
  error: null,
  slidsFetched: false,
  sections: [],
  packages: null,
  packagesLoading: false,
  packagesError: null,
  packagesFetched: false,
};

let homePageReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.FETCHING_HOMEPAGE_SLIDER_START:
      return {
        ...state,
        sliderLoading: true,
        error: null
      };
    case actions.FETCHING_HOMEPAGE_SLIDER_SUCCESS:
      return {
        ...state,
        sliderLoading: false,
        sliders: action.sliders,
        slidsFetched: true
      };
    case actions.FETCHING_HOMEPAGE_SLIDER_FAILED:
      return {
        ...state,
        sliderLoading: false,
        sliders: [],
        error: action.errorMsg
      };

    case actions.FETCHING_HOMEPAGE_PACKAGES_START:
      return {
        ...state,
        packagesLoading: true,
        packagesError: null
      };
    case actions.FETCHING_HOMEPAGE_PACKAGES_SUCCESS:
      return {
        ...state,
        packagesLoading: false,
        packages: action.packages,
        packagesFetched: true
      };
    case actions.FETCHING_HOMEPAGE_PACKAGES_FAILED:
      return {
        ...state,
        packagesLoading: false,
        packages: null,
        packagesError: action.errorMsg
      };

    case actions.FETCHING_HOMEPAGE_SECTIONS_START:
      return {
        ...state,
        sectionsLoading: true,
        sectionsError: null
      };
    case actions.FETCHING_HOMEPAGE_SECTIONS_SUCCESS:
      return {
        ...state,
        sectionsLoading: false,
        sections: action.sections,
        sectionsFetched: true
      };
    case actions.FETCHING_HOMEPAGE_SECTIONS_FAILED:
      return {
        ...state,
        sectionsLoading: false,
        sections: null,
        sectionsError: action.errorMsg
      };

    default:
      return state;
  }
};
export default homePageReducer;