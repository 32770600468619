import * as actions from "./actionsType";

let initialState = {
  loading: false
};
let loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.START_LOADING:
      return {
        ...state,
        loading: true
      };

    case actions.END_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
export default loadingReducer;
