import * as actions from './actionsType';
import { arrayToKeyedObject } from '../../util'

let initialState = {
  categories: null,
  error: null,
  loading: false,
  vanities: null,
  selectedNumber: null,
  selectedPlan: null,
  orderSessionID: null,
  widgets: null
};
let shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCHING_CATEGORIES_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case actions.FETCHING_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        categories: action.categories
      };
    case actions.FETCHING_CATEGORIES_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };

    case actions.FETCHING_VANINTIES_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case actions.FETCHING_VANINTIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        vanities: action.vanities
      };
    case actions.FETCHING_VANINTIES_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    case actions.SET_VANINTIES:
      return {
        ...state,
        error: null,
        loading: false,
        vanities: action.vanities
      };

    case actions.SELECTED_NUMBER:
      return {
        ...state,
        selectedNumber: action.selectedNumber
      };
    case actions.SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.selectedPlan
      };

    case actions.SET_ORDER_SESSION_ID:
      return {
        ...state,
        orderSessionID: action.orderSessionID
      };

    case actions.GET_SHOP_WIDGETS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.GET_SHOP_WIDGETS_SUCCESS:
      const widgets = arrayToKeyedObject(action.widgets, 'category')
      return {
        ...state,
        error: null,
        loading: false,
        widgets: widgets
      };
    case actions.GET_SHOP_WIDGETS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };
    case actions.GET_SHOP_CATEGORY_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actions.GET_SHOP_CATEGORY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        categories: action.categories
      };
    case actions.GET_SHOP_CATEGORY_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
      };


    default:
      return state;
  }
};
export default shopReducer;
