export const DASHBOARD_START = 'DASHBOARD_START';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';


// export const USER_PLANS_START = 'USER_PLANS_START';
export const USER_PLANS_SUCCESS = 'USER_PLANS_SUCCESS';
export const USER_PLANS_FAILED = 'USER_PLANS_FAILED';
export const USER_PLANS_RESET = 'USER_PLANS_RESET';




export const INITIAL_USER_DASHBOARD_START = 'INITIAL_USER_DASHBOARD_START';
export const INITIAL_USER_DASHBOARD_SUCCESS = 'INITIAL_USER_DASHBOARD_SUCCESS';
export const INITIAL_USER_DASHBOARD_FAILED = 'INITIAL_USER_DASHBOARD_FAILED';

export const CHANGE_SUBSCRIBER = 'CHANGE_SUBSCRIBER';