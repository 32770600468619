export const FETCHING_HOMEPAGE_SLIDER_START = 'FETCHING_HOMEPAGE_SLIDER_START';
export const FETCHING_HOMEPAGE_SLIDER_SUCCESS = 'FETCHING_HOMEPAGE_SLIDER_SUCCESS';
export const FETCHING_HOMEPAGE_SLIDER_FAILED = 'FETCHING_HOMEPAGE_SLIDER_FAILED';

export const FETCHING_HOMEPAGE_PACKAGES_START = 'FETCHING_HOMEPAGE_PACKAGES_START';
export const FETCHING_HOMEPAGE_PACKAGES_SUCCESS = 'FETCHING_HOMEPAGE_PACKAGES_SUCCESS';
export const FETCHING_HOMEPAGE_PACKAGES_FAILED = 'FETCHING_HOMEPAGE_PACKAGES_FAILED';

export const FETCHING_HOMEPAGE_SECTIONS_START = 'FETCHING_HOMEPAGE_SECTIONS_START';
export const FETCHING_HOMEPAGE_SECTIONS_SUCCESS = 'FETCHING_HOMEPAGE_SECTIONS_SUCCESS';
export const FETCHING_HOMEPAGE_SECTIONS_FAILED = 'FETCHING_HOMEPAGE_SECTIONS_FAILED';
