import HTTP from './http.service';

const Menu = {};

Menu.fetchingMenu = slug => {
  let url = '/content/menu/' + slug;
  const config = {
    method: 'get',
    url: url
  };

  return HTTP(config);
};

export default Menu;
