/* HTTP service */
import axios from 'axios';
import Settings from './settings.service';
import { getStorageElement } from './storage.service';
import { MAIN } from '../constants';
import { logoutAfterChangingPassword } from 'stores/User/userActions';
const store = require('../stores/RootReducer/rootReducer');

let device = '';
let authToken = '';

const HTTP = axios.create({
  baseURL: Settings.REACT_APP_API_BASE_URL,
  headers: {
    'x-protocol-version': Settings.REACT_APP_API_VERSION,
    'Content-Type': 'application/json',
  },
});
HTTP.defaults.headers.common.Accept = 'application/json';
///////////////////////////////
HTTP.interceptors.request.use(
  (config) => {
    config.headers['accept-language'] = window.appLang;
    if (config.headers['needAutherization'] === undefined) {
      if (config.headers['payloadLogin']) {
        config.headers['Authorization'] = config.headers['Authorization'] ? config.headers['Authorization'] : authToken;
      } else {
        config.headers['Authorization'] = authToken
      }
    }
    let subAccount = getStorageElement('subAccountId');
    if (subAccount && subAccount !== MAIN) {
      config.headers['x-sub-account'] = subAccount;
    } else {
      delete config.headers['x-sub-account'];
    }
    delete config.headers['needAutherization'];
    return config;
  },
  (error) => Promise.reject(error)
);
///////////////////////////////////
HTTP.interceptors.response.use(
  (response) => {
    return response;
  }
  ,
  error => {

    // error code = -306 && error sttaus = 428 ---> sub is is deactivated
    // if sub user deactivated removed (subAccountId) from storage and do hard refresh
    if (error?.response?.data?.code === -306 && error?.response?.status === 428) {
      localStorage.removeItem('subAccountId')
      window.location.reload();
    }
    const invalidSessionID = -15;
    if (
      error.response &&
      error.response.data &&
      error.response.data.code &&
      error.response.data.code === invalidSessionID
    ) {
      store.default.dispatch(logoutAfterChangingPassword());
    }
    return Promise.reject(error);
  }
);
/////////////////////////////////
HTTP.get_device_id = () => {
  return device;
};
/////////////////////////////////
HTTP.set_device_id = (val) => {
  device = val;
};
/////////////////////////////////
HTTP.set_session = (val) => {
  authToken = val;
};
/////////////////////////////////
HTTP.get_session = () => {
  return authToken
};
/////////////////////////////////
export default HTTP;
